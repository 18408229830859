<template>
    <div>
        
    </div>
</template>

<script>

import LoginModal from "@/components/LoginModal";
import { getUrlParam } from '@/utils/util'
export default {
  

  data() {
    return {
        //  appid : 'wx115a4979f2c8f081',
        //  scope : 'snsapi_login',
        //  redirect_uri : 'http://zz.lgs2020.top',
    };
  },

  mounted() {
    // console.log(window.location.href)
   

  const code = getUrlParam('code');//获取url 上面的code
  if(!code){
    let _this = this;
      _this.$dialog(
        LoginModal,
        // component props
        {
          record: {},
          on: {
            close() {
              console.log("modal close 回调");
              _this.$router.push({ path: "/store/home" });
            },
          },
        },
        // modal props
        {
          width: 360,
          centered: true,
          maskClosable: false,
          footer: "",
          bodyStyle: { padding: "0px", position: "relative" },
        }
      );
  }
    
  },


};
</script>